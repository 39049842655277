import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import JobApplication from './JobApplication';
import JobEmailForm from './JobEmailForm';

function App() {
 
  return (
    <BrowserRouter>
    <Routes>
        <Route exact path="/" element={<JobApplication/>}/>
        <Route exact path="/job-post-email" element={<JobEmailForm/>}/>

      </Routes>
      </BrowserRouter>
  );
}

export default App;
