import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Paper,
} from '@mui/material';
import './App.css';

function JobApplication() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    applyingFor: '',
    coverLetter: '',
    file: null,
  });

  const [jobPositions, setJobPositions] = useState([]); // State to hold job positions

  useEffect(() => {
    // Fetch job positions from the API
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/job-email`);
        setJobPositions(response.data.data); // Assuming the data is in 'data.data'
      } catch (error) {
        console.error('Error fetching job positions:', error);
      }
    };

    fetchJobPositions(); // Fetch job positions when the component mounts
  }, []); // Empty dependency array to run only once on mount

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, file: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('applyingFor', formData.applyingFor);
    formDataToSend.append('coverLetter', formData.coverLetter);
    formDataToSend.append('file', formData.file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/job-submission`,
        formDataToSend,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      alert('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4"></Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
            required
          />
                  </Box>

        <Box mb={2}>
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            fullWidth
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth required>
            <InputLabel>Applying For</InputLabel>
            <Select
              name="applyingFor"
              value={formData.applyingFor}
              onChange={handleInputChange}
            >
              {jobPositions.map((position) => (
                <MenuItem key={position._id} value={position.jobPosition}>
                  {position.jobPosition}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <TextField
            label="Cover Letter"
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            required
          />
        </Box>
        <Box mb={2}>
          <input
            type="file"
            name="file"
            onChange={handleFileChange}
            required
          />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Submit Application
        </Button>
      </form>
    </Paper>
  );
}

export default JobApplication;
