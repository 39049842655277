import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material'; // Delete icon

function JobEmailForm() {
  const [formData, setFormData] = useState({
    jobPosition: '',
    email: '',
  });

  const [jobEmailMappings, setJobEmailMappings] = useState([]); // Holds the records for the table

  // Fetch job-email mappings
  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/job-email`);
        setJobEmailMappings(response.data.data); // Set the fetched data
      } catch (error) {
        console.error('Error fetching job-email mappings:', error);
      }
    };

    fetchMappings(); // Fetch on component mount
  }, []); // Empty dependency array

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/job-email`, formData);
      alert('Job position and email mapping saved successfully');
      // After saving, refetch the data to update the table
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/job-email`);
      setJobEmailMappings(response.data.data);
    } catch (error) {
      console.error('Error saving job position and email mapping:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/job-email/${id}`);
      // After deletion, refetch the data to update the table
      const response = await axios.get('http://localhost:8080/api/job-email');
      setJobEmailMappings(response.data.data);
    } catch (error) {
      console.error('Error deleting job-email mapping:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <Paper style={{ padding: '16px', margin: '16px' }}>
        <Typography variant="h5">Add Job Position and Email</Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Job Position"
              name="jobPosition"
              value={formData.jobPosition}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Email Address"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
              type="email"
            />
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </form>
      </Paper>

      <Paper style={{ padding: '16px', margin: '16px' }}>
        <Typography variant="h6">Job-Email Mappings</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job Position</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobEmailMappings.map((mapping) => (
                <TableRow key={mapping._id}>
                  <TableCell>{mapping.jobPosition}</TableCell>
                  <TableCell>{mapping.email}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(mapping._id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default JobEmailForm;
